<template>
  <v-row justify="center">
    <v-dialog
      :value="dialog"
      v-on:input="updateValue($event)"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="$emit('update:dialog',false)">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="changes == 'sectorChange'">Sector Change</v-toolbar-title>
          <v-toolbar-title v-else-if="changes == 'dateChange'">Date Change</v-toolbar-title>
          <v-toolbar-title v-else>Date & Sector Change</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-title class="headline"></v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" md="3">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="way.travelDate"
                    label="TravelDate *"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" @input="dateMenu = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2" offset-md="1">
              <v-text-field
                v-model="way.dept"
                label="Time"
                prepend-icon="access_time"
                v-mask="mask"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" v-if="way.id == 0" class="pl-3">
              <v-text-field label="Code" v-model="way.code" />
            </v-col>
            <v-col cols="12" md="3" class="pl-3">
              <template v-for="(person,index) in forPerson">
                <v-chip
                  outlined
                  v-if="!person.isClose"
                  :key="index"
                  class="ma-1"
                  close
                  @click:close="closeChip(person)"
                >{{person.pass.passTypeName}} {{person.name}}</v-chip>
              </template>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="3">
              <v-autocomplete
                label="Airline"
                prepend-icon="local_airport"
                :items="listAirline"
                item-text="airlineName"
                item-value="airlineId"
                v-model="selectedAirline"
                return-object
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="3">
              <v-autocomplete
                label="Flight"
                prepend-icon="flight"
                :items="listFlight"
                item-text="flightName"
                item-value="flightName"
                v-model="way.flight"
                return-object
              />
            </v-col>
            <v-col cols="12" md="2" offset-md="1">
              <v-autocomplete
                label="From"
                prepend-icon="trending_up"
                :items="from"
                item-text="sectorName"
                v-model="way.fromSector"
                return-object
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.sectorName"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.shortName"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" class="ml-md-2">
              <v-autocomplete
                label="To"
                prepend-icon="trending_down"
                :items="to"
                item-text="sectorName"
                v-model="way.toSector"
                return-object
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.sectorName"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.shortName"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="3">
              <v-autocomplete
                label="Class"
                prepend-icon="category"
                :items="listClass"
                item-text="className"
                item-value="className"
                v-model="way.className"
              />
            </v-col>
            <v-col cols="12" md="2" offset-md="1" v-if="way.roundTripId != null ">
              <v-checkbox label="RoundTrip" v-model="way.changeRoundTrip"></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              md="2"
              v-if="way.changeRoundTrip && (changes == 'dateChange' || changes == 'dateAndSectorChange')"
              class="ml-md-2"
            >
              <v-menu
                v-model="dateMenuRoundTrip"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="way.roundTripDate"
                    label="TravelDate *"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    required
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateRoundTrip" @input="dateMenuRoundTrip = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="3">
              <v-radio-group label="Cash" v-model="moneyType" prepend-icon="money" row>
                <v-radio label="MMK" value="mmk"></v-radio>
                <v-radio label="USD" value="usd"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="4">
              <v-radio-group v-model="way.passType" row @change="changePass">
                <v-radio value="ADULT" :label="`Adult : ${adult}`"></v-radio>
                <v-radio value="CHILD" :label="`Child : ${child}`"></v-radio>
                <v-radio value="INFANT" :label="`Infant : ${infant}`">
                  <!-- <v-label>
                    Infant
                    <v-chip color="primary" outlined @click="way.pass = infant">{{infant}}</v-chip>
                  </v-label>-->
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6" md="1">
              <v-text-field
                label="Pax"
                type="number"
                v-model.number="way.pass"
                prepend-icon="group"
              />
            </v-col>
            <v-col cols="6" md="1" offset-md="1">
              <v-text-field
                label="Fare"
                type="number"
                v-model.number="way.fare"
                prepend-icon="local_offer"
              />
            </v-col>
            <v-col cols="12" md="1" offset-md="1">
              <v-text-field
                v-if="moneyType == 'usd'"
                label="Amount USD"
                type="number"
                v-model.number="way.usdAmount"
              />
              <v-text-field v-else label="Amount MMK" type="number" v-model.number="way.mmkAmount" />
            </v-col>
            <v-col cols="12" md="2" offset-md="1">
              <!-- <v-row no-gutters>
                <v-col>
                  <v-btn class="mt-2 ml-3" rounded color="primary" @click="calculate">Calculate</v-btn>
                </v-col>
              </v-row>-->
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" md="2" class="pa-1">
              <v-text-field label="TotalMMK" v-model.number="voucher.mmkTotal" readonly dense/>
            </v-col>
            <v-col cols="5" md="2" class="pa-1">
              <v-text-field label="TotalUSD" v-model.number="voucher.usdTotal" readonly dense/>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="5" md="2" class="pa-1">
              <v-text-field label="MMK Receive" v-model.number="voucher.mmkReceive" type="number" dense/>
            </v-col>
            <v-col cols="5" md="2" class="pa-1">
              <v-text-field label="USD Receive" v-model.number="voucher.usdReceive" type="number" dense/>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6" md="2">
              <v-text-field
                dense
                outlined
                label="Issued by:"
                disabled
                v-model="voucher.issuedBy"
                class="pa-1"
                hide-details
              />
            </v-col>
            <v-col cols="6" md="2">
              <v-text-field
                dense
                outlined
                label="PNR"
                v-model="voucher.pnr"
                class="pa-1"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-textarea
                dense
                outlined
                rows="3"
                label="Remark:"
                v-model="voucher.remark"
                class="pa-1"
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="3" v-if="way.id == 0">
              <v-btn
                v-if="changes == 'sectorChange'"
                block
                color="primary"
                @click="saveSectorChange(way)"
              >
                <v-icon>save</v-icon>Save Sector Change
              </v-btn>
              <v-btn
                v-else-if="changes == 'dateChange'"
                block
                color="primary"
                @click="saveDateChange(way)"
              >
                <v-icon>save</v-icon>Save Date Change
              </v-btn>
              <v-btn v-else block color="primary" @click="saveDateAndSectorChange(way)">
                <v-icon>save</v-icon>Save Date & Sector Change
              </v-btn>
            </v-col>
            <v-col cols="12" md="3" v-else>
              <v-btn
                v-if="changes == 'sectorChange'"
                block
                color="primary"
                @click="updateSectorChange(way)"
              >
                <v-icon>update</v-icon>Update Sector Change
              </v-btn>
              <v-btn
                v-else-if="changes == 'dateChange'"
                block
                color="primary"
                @click="updateDateChange(way)"
              >
                <v-icon>update</v-icon>Update Date Change
              </v-btn>
              <v-btn v-else block color="primary" @click="updateDateAndSectorChange(way)">
                <v-icon>update</v-icon>Update Date & Sector Change
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mask } from "vue-the-mask";
import airlineService from "../service/AirlineService";
import flightService from "../service/FlightService";
import sectorService from "../service/SectorService";
import settingService from "../service/SettingService";
import voucherService from "../service/VoucherService";

export default {
  directives: {
    mask
  },
  mounted: function() {},
  data() {
    return {
      listAirline: [],
      listFlight: [],
      listClass: [],
      listSector: [],
      mask: "##:##",
      selectedAirline: {
        airlineId: 0,
        airlineName: ""
      },
      //   selectedFlight: {
      //     flightId: 0,
      //     flightName: ""
      //   },
      //   selectedClass: {},
      dateMenu: false,

      timeMenu: false,
      moneyType: "mmk",
      from: [],
      to: [],
      voucher: {
        usdTotal: 0,
        mmkTotal: 0,
        pnr: "",
        remark: "",
        forPerson: []
      },
      dateMenuRoundTrip: false
    };
  },
  props: {
    dialog: {
      type: Boolean
    },
    way: {
      type: Object,
      default: () => {
        return {
          id: Number, //changes Id
          travelDate: "", //16 Dec
          flight: {
            flightId: 0,
            flightName: ""
          },
          className: "",
          dept: "", //11:10
          fromSector: {
            sectorId: 0,
            sectorName: ""
          },
          toSector: {
            sectorId: 0,
            sectorName: ""
          },
          pass: 0,
          passType: "ADULT", //CHILD, INFANT
          fare: 0,
          baseFare: 0,
          commissionPercent: 0,
          commission: 0,
          usdTotalCommission: 0,
          mmkTotalCommission: 0,
          usdAmount: 0,
          mmkAmount: 0,
          roundTripDate: String
        };
      }
    },
    forPerson: Array,
    voucherId: Number,
    saveWay: Function,
    updateWay: Function,
    changes: String,
    changesVoucher: {
      usdTotal: 0,
      mmkTotal: 0,
      pnr: "",
      remark: "",
      forPerson: []
    }
  },
  methods: {
    calculate: function() {
      this.way.mmkAmount = 0;
      this.way.usdAmount = 0;
      if (this.moneyType == "mmk") {
        this.way.mmkAmount = this.way.fare * this.way.pass;
      } else {
        this.way.usdAmount = this.way.fare * this.way.pass;
      }
    },
    saveDateChange: function() {
      this.voucher.way = this.way;
      voucherService
        .saveDateChange(this.voucherId, this.voucher)
        .then(data => {
          this.$emit("update:dialog", false);
              this.saveWay();
        })
        .catch(e => {
          this.$swal2.fire({
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false
          });
        });
    },
    saveSectorChange: function() {
      this.voucher.way = this.way;
      voucherService
        .saveSectorChange(this.voucherId, this.voucher)
        .then(data => {
          this.$swal2
            .fire({
              icon: "success",
              title: "Change Voucher success",
              showConfirmButton: false,
              timer: 1500
            })
            .then(v => {
              this.$emit("update:dialog", false);
              this.saveWay();
            });
        })
        .catch(e => {
          this.$swal2.fire({
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false
          });
        });
    },
    saveDateAndSectorChange: function() {
      this.voucher.way = this.way;
      voucherService
        .saveDateAndSectorChange(this.voucherId, this.voucher)
        .then(data => {
          this.$swal2
            .fire({
              icon: "success",
              title: "Change Voucher success",
              showConfirmButton: false,
              timer: 1500
            })
            .then(v => {
              this.$emit("update:dialog", false);
              this.saveWay();
            });
        })
        .catch(e => {
          this.$swal2.fire({
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false
          });
        });
    },
    updateSectorChange: function() {
      this.voucher.way = this.way;
      voucherService
        .updateSectorChange(this.voucherId, this.voucher)
        .then(data => {
          this.$swal2
            .fire({
              icon: "success",
              title: "Change Voucher success",
              showConfirmButton: false,
              timer: 1500
            })
            .then(v => {
              this.$emit("update:dialog", false);
              this.updateWay();
            });
        })
        .catch(e => {
          this.$swal2.fire({
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false
          });
        });
    },
    updateDateChange: function() {
      this.voucher.way = this.way;
      voucherService
        .updateDateChange(this.voucherId, this.voucher)
        .then(data => {
          this.$swal2
            .fire({
              icon: "success",
              title: "Change Voucher success",
              showConfirmButton: false,
              timer: 1500
            })
            .then(v => {
              this.$emit("update:dialog", false);
              this.updateWay();
            });
        })
        .catch(e => {
          this.$swal2.fire({
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false
          });
        });
    },
    updateDateAndSectorChange: function() {
      this.voucher.way = this.way;
      voucherService
        .updateDateAndSectorChange(this.voucherId, this.voucher)
        .then(data => {
          this.$swal2
            .fire({
              icon: "success",
              title: "Change Voucher success",
              showConfirmButton: false,
              timer: 1500
            })
            .then(v => {
              this.$emit("update:dialog", false);
              this.updateWay();
            });
        })
        .catch(e => {
          this.$swal2.fire({
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false
          });
        });
    },
    changePass: function() {},
    closeChip: function(person) {
      this.$set(person, "isClose", true);
    }
  },
  computed: {
    date: {
      set(value) {
        this.way.travelDate = this.$moment(
          this.$moment(value, "YYYY-MM-DD")
        ).format("DD MMM YY");
      },
      get() {
        if (this.way != null && this.way.travelDate == "") {
          return this.way.travelDate;
        }
        return this.$moment(
          this.$moment(this.way.travelDate, "DD MMM YY")
        ).format("YYYY-MM-DD");
      }
    },
    dateRoundTrip: {
      set(value) {
        this.way.roundTripDate = this.$moment(
          this.$moment(value, "YYYY-MM-DD")
        ).format("DD MMM YY");
      },
      get() {
        if (this.way != null && this.way.roundTripDate == "") {
          return this.way.roundTripDate;
        }
        return this.$moment(
          this.$moment(this.way.roundTripDate, "DD MMM YY")
        ).format("YYYY-MM-DD");
      }
    },
    //ADULT,CHILD,INFANT
    adult: function() {
      return this.voucher.forPerson.filter(value => {
        if (value.pass.passType == "ADULT") {
          return true;
        }
        return false;
      }).length;
    },
    child: function() {
      return this.voucher.forPerson.filter(value => {
        if (value.pass.passType == "CHILD") {
          return true;
        }
        return false;
      }).length;
    },
    infant: function() {
      return this.voucher.forPerson.filter(value => {
        if (value.pass.passType == "INFANT") {
          return true;
        }
        return false;
      }).length;
    },
    amount: function() {
      return this.way.fare * this.way.pass;
    }
  },
  watch: {
    dialog: function(value) {
      if (value) {
        if (this.way.wayId != 0) {
          if (this.way.mmkAmount > 0) {
            this.moneyType = "mmk";
          } else {
            this.moneyType = "usd";
          }
        }
        airlineService.getAllAirline().then(data => {
          this.listAirline = this.listAirline.filter(f => false);
          this.listAirline.push(...data);
          if (this.way.wayId != 0) {
            this.selectedAirline.airlineId = this.way.flight.airline.airlineId;
          }
        });
        settingService.getClasses().then(data => {
          this.listClass = this.listClass.filter(f => false);
          this.listClass.push(...data);
        });
      }
    },
    "selectedAirline.airlineId": function(value) {
      sectorService.getAllSectorByAirlineId(value).then(data => {
        this.listSector = this.listSector.filter(f => false);
        this.listSector.push(...data);
        this.to = this.listSector;
        this.from = this.listSector;
      });
      flightService.getFlightByAirlineId(value).then(data => {
        this.listFlight = this.listFlight.filter(f => false);
        this.listFlight.push(...data);
      });
    },
    "way.toSector.sectorId": function() {
      this.from = this.from.filter(f => false);
      this.from.push(
        ...this.listSector.filter(data => {
          if (this.way.toSector.sectorId == data.sectorId) {
            return false;
          }
          return true;
        })
      );
    },
    "way.fromSector.sectorId": function() {
      this.to = this.to.filter(f => false);
      this.to.push(
        ...this.listSector.filter(data => {
          if (this.way.fromSector.sectorId == data.sectorId) {
            return false;
          }
          return true;
        })
      );
    },
    "way.mmkAmount": function(value) {
      this.voucher.mmkTotal = value;
    },
    "way.usdAmount": function(value) {
      this.voucher.usdTotal = value;
    },
    moneyType: function() {
      this.calculate();
    },
    forPerson: {
      deep: true,
      handler: function() {
        if (this.forPerson == null) {
          return;
        }
        let result = this.forPerson.filter(person => {
          return !person.isClose;
        });
        this.voucher.forPerson = this.voucher.forPerson.filter(f => false);
        this.voucher.forPerson.push(...result);
      }
    },
    changesVoucher: function() {
      if (this.voucher != null) {
        this.voucher.status = this.changesVoucher.status;
        this.voucher.remark = this.changesVoucher.remark;
        this.voucher.issuedBy = this.changesVoucher.issuedBy;
        this.voucher.pnr = this.changesVoucher.pnr;
        this.voucher.date = this.changesVoucher.date;
        this.voucher.mmkReceive = this.changesVoucher.mmkReceive;
        this.voucher.usdReceive = this.changesVoucher.usdReceive;
      }
    },
    amount: function() {
      this.calculate();
    }
  }
};
</script>

<style scoped>
</style>