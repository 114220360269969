<template>
  <v-row>
    <v-col cols="12" md="3" offset-md="1" class="ma-3">
      <div style="height: 75vh;">
        <v-alert border="left" dense text color="primary">Name Change</v-alert>
        <v-alert
          class="mt-2"
          border="left"
          dense
          text
          color="primary"
        >VoucherCode: {{voucher.voucherCode}}</v-alert>
        <v-alert border="left" dense text color="primary">For</v-alert>
        <template v-for="(person, index) in dateVoucher.forPerson">
          <v-row no-gutters :key="index">
            <v-col cols="4">
              <v-select
                label="PassType"
                v-model="dateVoucher.forPerson[index].pass"
                :items="listPassType"
                return-object
                item-text="passTypeName"
                item-value="passTypeName"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field label="Name" v-model="person.name" @keydown="person.status = 'change'"></v-text-field>
            </v-col>
            <v-col cols="2">
              <!-- <v-btn
                v-if="index != 0"
                class="mt-2 ml-2"
                text
                icon
                color="red"
                @click="removeFor(person, index)"
              >
                <v-icon>clear</v-icon>
              </v-btn>-->
            </v-col>
          </v-row>
        </template>
        <v-row no-gutters></v-row>
      </div>
    </v-col>
    <v-col cols="12" md="4" class="pl-4">
      <v-row no-gutters>
        <v-col cols="6" md="4">
          <v-text-field
            label="Passenger"
            type="number"
            v-model.number="dateVoucher.way.pass"
            prepend-icon="group"
          />
        </v-col>
        <v-col cols="6" md="4" offset-md="1">
          <v-text-field
            label="Fare"
            type="number"
            v-model.number="dateVoucher.way.fare"
            prepend-icon="local_offer"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-radio-group label="Cash" v-model="moneyType" prepend-icon="money" row>
          <v-radio label="MMK" value="mmk"></v-radio>
          <v-radio label="USD" value="usd"></v-radio>
        </v-radio-group>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4" md="4" class="pt-3 pl-2">
          <v-label>Total Amount</v-label>
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field dense outlined label="MMK" v-model.number="dateVoucher.mmkTotal" readonly />
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field dense outlined label="USD" v-model.number="dateVoucher.usdTotal" readonly />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="4" md="4" class="pt-3 pl-2">
          <v-label>Receive Amount</v-label>
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field dense outlined label="MMK" v-model.number="dateVoucher.mmkReceive" type="number" />
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field dense outlined label="USD" v-model.number="dateVoucher.usdReceive" type="number" />
        </v-col>
      </v-row>
      <!-- <v-row no-gutters style="border: 1px solid rgba(0, 0, 0, 0.12);" class="mt-1">
        <v-col cols="4" md="4" class="pt-5 pl-2">
          <v-label>Received</v-label>
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field label="MMK" v-model.number="dateVoucher.mmkReceive" />
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field label="USD" v-model.number="dateVoucher.usdReceive" />
        </v-col>
      </v-row>-->
      <!-- <v-row no-gutters style="border: 1px solid rgba(0, 0, 0, 0.12);" class="mt-1">
        <v-col cols="4" md="4" class="pt-6 pl-2">
          <v-label>Balance</v-label>
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field label="MMK" v-model.number="dateVoucher.mmkBalance" readonly />
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field label="USD" v-model.number="dateVoucher.usdBalance" readonly />
        </v-col>
      </v-row>-->

      <v-row no-gutters>
        <v-col cols="6">
          <v-text-field
            dense
            outlined
            label="Issued by:"
            disabled
            v-model="voucher.issuedBy"
            class="pa-1"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            dense
            outlined
            label="PNR"
            v-model="dateVoucher.pnr"
            class="pa-1"
            hide-details
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-textarea
          dense
          outlined
          rows="3"
          label="Remark:"
          v-model="dateVoucher.remark"
          class="pa-1"
        />
      </v-row>
    </v-col>

    <v-col cols="12" md="3" offset-md="2">
      <v-btn
        v-if="dateVoucher.changeId == 0"
        block
        color="primary"
        @click="addChange"
      >Add Name Change</v-btn>
      <v-btn block color="primary" v-else @click="updateChange">Update</v-btn>
    </v-col>
  </v-row>
</template>
<script>
import customerService from "../service/CustomerService";
import voucherService from "../service/VoucherService";

export default {
  mounted: function() {
    let voucherId = this.$route.params.voucherId;
    let changesId = this.$route.params.changesId;
    if (changesId != undefined) {
      voucherService
        .getNameChangeByChangesId({ voucherId, changesId })
        .then(data => {
          this.dateVoucher.issuedBy = data.issuedBy;
          this.dateVoucher.changeId = data.changeId;
          this.dateVoucher.voucherId = data.voucherId;
          this.dateVoucher.pnr = data.pnr;
          this.dateVoucher.way = data.way;
          this.dateVoucher.remark = data.remark;
          this.dateVoucher.date = data.date;
          this.dateVoucher.usdReceive = data.usdReceive;
          this.dateVoucher.mmkReceive = data.mmkReceive;
        });
    }

    voucherService.getVoucherId(voucherId).then(data => {
      this.voucher = data;

      this.dateVoucher.forPerson.push(...data.forPerson);
      this.dateVoucher.voucherId = data.voucherId;
    });
    customerService.getGenderType().then(data => {
      this.listPassType.push(...data);
    });
  },
  data() {
    return {
      voucher: {
        forPerson: []
      },
      dateVoucher: {
        changeId: 0,
        forPerson: [],
        way: {
          pass: 0,
          fare: 0,
          usdAmount: 0,
          mmkAmount: 0
        },
        usdTotal: 0,
        mmkTotal: 0,
        pnr: "",
        remark: ""
      },
      listPassType: [],
      moneyType: "mmk"
    };
  },
  methods: {
    addNewFor: function() {
      this.dateVoucher.forPerson.push({
        name: "",
        pass: {
          passType: "",
          passTypeName: ""
        }
      });
    },
    removeFor: function(person, index) {
      this.$delete(this.dateVoucher.forPerson, index);
    },
    addChange: function() {
      voucherService
        .nameChange(this.dateVoucher.voucherId, this.dateVoucher)
        .then(data => {
          this.$swal2
            .fire({
              icon: "success",
              title: "'Name Changed' success",
              showConfirmButton: false,
              timer: 1500
            })
            .then(v => {
              let date = this.$moment().format("DD-MM-YYYY");
              let query = {
                fromDate: date,
                toDate: date
              };
              this.$router
                .push({
                  path: "/voucherList",
                  query
                })
                .catch(e => {});
            });
        })
        .catch(e => {
          console.log("Error");
          this.$swal2.fire({
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false
          });
        });
    },
    updateChange: function() {
      voucherService
        .nameChangeUpdate({
          voucherId: this.dateVoucher.voucherId,
          changesId: this.dateVoucher.changeId,
          voucher: this.dateVoucher
        })
        .then(data => {
          this.$swal2
            .fire({
              icon: "success",
              title: "'Name Changed' success",
              showConfirmButton: false,
              timer: 1500
            })
            .then(v => {
              let date = this.$moment().format("DD-MM-YYYY");
              let query = {
                fromDate: date,
                toDate: date
              };
              this.$router
                .push({
                  path: "/voucherList",
                  query
                })
                .catch(e => {});
            });
        })
        .catch(e => {
          console.log("Error");
          this.$swal2.fire({
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false
          });
        });
    }
  },
  computed: {
    total: function() {
      let result = this.dateVoucher.way.fare * this.dateVoucher.way.pass;

      return result;
    }
  },
  watch: {
    total: function(value) {
      if (this.moneyType == "mmk") {
        this.dateVoucher.mmkTotal = value;
        this.dateVoucher.usdTotal = 0;
      } else {
        this.dateVoucher.usdTotal = value;
        this.dateVoucher.mmkTotal = 0;
      }
    },
    moneyType: function(value) {
      if (this.moneyType == "mmk") {
        this.dateVoucher.mmkTotal = this.total;
        this.dateVoucher.usdTotal = 0;
      } else {
        this.dateVoucher.usdTotal = this.total;
        this.dateVoucher.mmkTotal = 0;
      }
    }
  }
};
</script>

<style scoped>
</style>