<template>
  <v-row no-gutters>
    <v-col cols="12" md="8" class="pa-1 pt-2">
      <v-alert border="left" dense text color="primary">VoucherCode: {{voucher.voucherCode}}</v-alert>
      <v-alert class="mt-2" border="left" dense text color="primary">
        <v-radio-group v-model="changes" row>
          <v-radio value="dateChange" label="DateChange"></v-radio>
          <v-radio value="sectorChange" label="SectorChange"></v-radio>
          <v-radio value="dateAndSectorChange" label="Date & SectorChange"></v-radio>
        </v-radio-group>
      </v-alert>
      <v-data-table
        :mobile-breakpoint="NaN"
        :headers="headers"
        :items-per-page="-1"
        hide-default-footer
        hide-default-header
        height="65vh"
        :items="voucher.way"
        class="tbl-way"
      >
        <template v-slot:header="{ headers }">
          <thead>
            <tr>
              <th rowspan="2">Travel Date</th>
              <th rowspan="2">Voucher Status</th>
              <th rowspan="2">Flight</th>
              <th rowspan="2">Class</th>
              <th rowspan="2">Dep</th>
              <th rowspan="2">From</th>
              <th rowspan="2">To</th>
              <th rowspan="2">Pax</th>
              <th rowspan="2">Fare</th>
              <th colspan="2">Amout</th>
              <th rowspan="2">Action</th>
            </tr>
            <tr>
              <th>MMK</th>
              <th>USD</th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{ items }" v-if="voucher.way != null && voucher.way.length > 0">
          <tbody>
            <template v-for="(item, index) in items">
              <template
                v-if="item.status != 'INACTIVE' && item.status != 'WEIGHT' && item.status !='WHEEL'"
              >
                <tr :key="index + 1" :class="rowHighlight(item.wayId)">
                  <td>{{ item.travelDate }}</td>
                  <td>{{item | voucherStatus}}</td>
                  <td>{{ item.flight.flightName }}</td>
                  <td>{{ item.className }}</td>
                  <td>{{ item.dept }}</td>
                  <td>{{ item.fromSector.sectorName }}</td>
                  <td>{{ item.toSector.sectorName }}</td>
                  <td>{{ item.pass }}</td>
                  <td>{{ item.fare }}</td>
                  <td>{{ item.mmkAmount | zeroFilter}}</td>
                  <td>{{ item.usdAmount | zeroFilter}}</td>
                  <td>
                    <v-btn
                      v-if="!item.code.includes('AR')"
                      text
                      icon
                      small
                      color="primary"
                      class="ml-2 mr-2"
                      @click="editWay(item)"
                    >
                      <v-icon>add</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="12" md="4" class="pa-1 pt-2">
      <voucher-history
        :voucherHistoy="voucherHistoy"
        @editVoucherHistory="editVoucherHistory"
        :hideButton="false"
      />
    </v-col>
    <changes-way-dialog
      :dialog.sync="wayDialog"
      :changesVoucher="changesVoucher"
      :way="way"
      :forPerson="forPerson"
      :changes="changes"
      :voucherId="voucher.voucherId"
      :saveWay="saveWay"
      :updateWay="updateWay"
    />
  </v-row>
</template>
<script>
import ChangesWayDialog from "../components/ChangesWayDialog";
import HistoryComponent from "../components/HistoryComponent";
import voucherService from "../service/VoucherService";

export default {
  mounted: function() {
    let codeId = this.$route.query.codeId;
    this.codeId = codeId;
    this.loadVoucher();
  },
  data() {
    return {
      voucherHistoy: [],
      voucher: {
        voucherId: 0,
        voucherCode: "",
        forPerson: [
          // {
          //   name: "",
          //   pass: {
          //     passType: "", //ADULT,CHILD,INFANT
          //     passTypeName: ""
          //   }
          // }
        ],
        toCustomer: {
          customerId: 0,
          customerName: "",
          phone: "",
          address: "",
          type: "AGENT"
        },
        pnr: "",
        date: "", //15 Dec 19
        way: [
          // {
          //   travelDate: "16 Dec", //16 Dec
          //   flight: {
          //     flightId: 5,
          //     flightName: "F0004"
          //   },
          //   className: "Low",
          //   dept: "11:10", //11:10
          //   fromSector: {
          //     sectorId: 1,
          //     sectorName: "MDL"
          //   },
          //   toSector: {
          //     sectorId: 2,
          //     sectorName: "YGN"
          //   },
          //   pass: 2,
          //   passType: "ADULT", //CHILD, INFANT
          //   fare: 5,
          //   baseFare: 3,
          //   commissionPercent: 0.0,
          //   commission: 0,
          //   usdTotalCommission: 0,
          //   mmkTotalCommission: 0,
          //   usdAmount: 10,
          //   mmkAmount: 0
          // }
        ],
        usdTotal: 0,
        mmkTotal: 0,
        usdReceive: 0,
        mmkReceive: 0,
        usdBalance: 0,
        mmkBalance: 0,
        mmkDiscount: 0,
        usdDiscount: 0,
        issuedBy: "",
        remark: "",
        codeId: null
      },
      headers: [
        {
          text: "Travel Date",
          value: "travelDate"
        },
        {
          text: "Flight",
          value: "flight.flightName"
        },
        {
          text: "Class",
          value: "className"
        },
        {
          text: "Dep",
          value: "dept"
        },
        {
          text: "From",
          value: "fromSector.sectorName"
        },
        {
          text: "To",
          value: "toSector.sectorName"
        },
        {
          text: "Pax",
          value: "pass"
        },
        {
          text: "Fare",
          value: "fare"
        },
        {
          text: "MMK",
          value: "mmkAmount"
        },
        {
          text: "USD",
          value: "usdAmount"
        },
        {
          text: "Action",
          value: "action"
        }
      ],
      wayDialog: false,
      way: {
        wayId: 0,
        travelDate: "", //16 Dec
        flight: {
          flightId: 0,
          flightName: ""
        },
        className: "",
        dept: "", //11:10
        fromSector: {
          sectorId: 0,
          sectorName: ""
        },
        toSector: {
          sectorId: 0,
          sectorName: ""
        },
        pass: 0,
        passType: "ADULT", //CHILD, INFANT
        fare: 0,
        baseFare: 0,
        commissionPercent: 0,
        commission: 0,
        usdTotalCommission: 0,
        mmkTotalCommission: 0,
        usdAmount: 0,
        mmkAmount: 0,
        roundTripDate: "",
        changeRoundTrip: false
      },
      forPerson: [],
      changes: "dateChange",
      changesVoucher: {}
    };
  },
  methods: {
    getVoucherHistoryChange: function(voucherId) {
      voucherService.getVoucherHistoryChange(voucherId).then(response => {
        this.voucherHistoy.splice(0, this.voucherHistoy.length);
        this.voucherHistoy.push(...response);

        let selectedVoucher = this.voucherHistoy.find(p => {
          if (p.voucherCode == this.codeId) {
            return true;
          }
          return false;
        });
        if (selectedVoucher != null) {
          this.editVoucherHistory(selectedVoucher);
        }
      });
    },
    editVoucherHistory: function(voucher) {
      this.way = voucher.way[0];

      //roundTripDate: "",
      //changeRoundTrip: false

      let personList = JSON.parse(JSON.stringify(this.voucher.forPerson));
      this.forPerson = this.forPerson.filter(f => false);
      this.forPerson.push(...personList);
      if (this.way.status == "N") {
        this.$router.push({
          name: "nameChangeUpdate",
          params: { voucherId: voucher.voucherId, changesId: this.way.id }
        });
      } else {
        this.changesVoucher = voucher;
        if (this.way.status == "D") {
          this.changes = "dateChange";
        } else if (this.way.status == "S") {
          this.changes = "sectorChange";
        } else {
          this.changes = "dateAndSectorChange";
        }
        this.wayDialog = true;
      }
    },
    updateVoucher: function() {},
    updateWay: function() {
      this.loadVoucher();
    },
    saveWay: function() {
      this.loadVoucher();
    },
    editWay: function(item) {
      this.way = JSON.parse(JSON.stringify(item));
      console.log("Way");
      console.log(this.way);
      this.$set(this.way, "roundTripDate", "");
      this.$set(this.way, "changeRoundTrip", false);
      this.changesVoucher = {
        usdTotal: 0,
        mmkTotal: 0,
        pnr: "",
        remark: "",
        forPerson: []
      };
      this.way.usdAmount = 0;
      this.way.mmkAmount = 0;
      this.way.fare = 0;
      this.way.pass = 0;
      this.way.passType = "";
      let personList = JSON.parse(JSON.stringify(this.voucher.forPerson));
      this.forPerson = this.forPerson.filter(f => false);
      this.forPerson.push(...personList);

      this.wayDialog = true;
    },
    loadVoucher: function() {
      let voucherId = this.$route.params.voucherId;
      voucherService.getLastVoucherById(voucherId).then(data => {
        this.voucher = data;
        console.log("GET last");
        console.log(data);
        this.getVoucherHistoryChange(voucherId);
      });
    },
    rowHighlight: function(wayId) {
      let resultClass = "";
      let i = wayId / 3;
      if (i == 0) {
        resultClass = "color1";
      } else if (i == 1) {
        resultClass = "color2";
      } else if (i == 2) {
        resultClass = "color3";
      } else {
        resultClass = "color4";
      }
      return resultClass;
    }
  },
  components: {
    "changes-way-dialog": ChangesWayDialog,
    "voucher-history": HistoryComponent
  },
  filters: {
    zeroMinusFilter: function(value) {
      if (value == 0) {
        return "";
      } else {
        return -Math.abs(value);
      }
    },
    zeroFilter: function(value) {
      if (value == 0) {
        return "";
      }
      return value;
    },
    voucherStatus: function(value) {
      if (value.status == "S") {
        return "SectorChange";
      } else if (value.status == "D") {
        return "DateChange";
      } else if (value.status == "N") {
        return "NameChange";
      } else if (value.status == "DNS") {
        return "Date & Sector Change";
      } else {
        if (value.code.includes("AR")) {
          return "Refund";
        }
        return "OriginVoucher";
      }
    }
  }
};
</script>

<style scoped>
.tbl-way table,
.tbl-way td,
.tbl-way th {
  padding: 4px;
}

.tbl-way thead td {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
  padding-right: 3px;
  border: 1px solid #e0e0e0;
  background: #4caf50 !important;
  text-align: center !important;
  color: white !important;
}

.tbl-way thead th {
  font-size: 14px;
  padding: 2px;
  height: 24px;
  border: 1px solid #e0e0e0;
  background: #4caf50 !important;
  text-align: center !important;
  color: white !important;
}

.tbl-way tbody th,
.tbl-way tbody td {
  font-size: 13px;
  padding-left: 3px;
  border: 1px solid #e7e7e7;
  padding-right: 1px;
}
.row-shadow {
  box-shadow: inset 0px 2px 8px -5px rgba(50, 50, 50, 0.75),
    inset 0px -2px 8px -5px rgba(50, 50, 50, 0.75);
}
</style>